








































































































































































































import {Vue, Component, Prop} from 'vue-property-decorator'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup,
  // BFormCheckbox,
  BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {VForm} from "@/global";

@Component({
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
})
export default class Login extends Vue {
  status = ''
  password = ''
  userEmail = ''
  sideImg = require('@/assets/images/pages/login-v2.svg')

  passwordFieldType = 'password';

  async beforeMount() {
    // @ts-ignore
    if (this.$msal.isAuthenticated()) {
      //await this.$router.push({name: 'home'})
      await this.$store.dispatch("user/LOGIN_CHECK");
    }
  }

  get loginCheck() {
    // @ts-ignore
    if (!this.$msal.isAuthenticated())
      return false;

    if (!this.$store.getters['user/IS_USER_LOGGING_IN'])
      return false;

    return true
  }

  get passwordToggleIcon(): string {
    return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
  }

  get imgUrl(): string {
    // @ts-ignore
    if (store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      return this.sideImg
    }
    return this.sideImg
  }

  async login(): Promise<void> {
    // @ts-ignore
    this.$msal.signIn();
  }

  get form(): VForm {
    return this.$refs.form as VForm
  }

  async validationForm(): Promise<void> {
    // @ts-ignore
    const isValid = await this.form.validate()
    if (!isValid) return

    await this.login()
  }
}
